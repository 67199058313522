<div class="flex flex-col h-screen">
  <div class="bg-[#f2f7f7] text-gray-500 px-4 py-2 sticky top-0 z-10">
    <div class="flex justify-between items-center">
      <div class="flex space-x-4">
        <button
          [routerLink]="['/auth/dashboard']"
          routerLinkActive="router-link-active"
          [disabled]="isAdmin"
          matTooltip="{{ 'DASHBOARD' | translate }}"
          class="disabled:opacity-50 disabled:cursor-not-allowed">
          <img
            ngSrc="assets/icons/header_1.svg"
            alt=""
            width="40"
            height="40" />
        </button>

        <button
          (click)="openIframe('commissioning')"
          [disabled]="!canAccessEntregaTecnica()"
          matTooltip="{{ 'ENTREGA_TECNICA' | translate }}"
          class="disabled:opacity-50 disabled:cursor-not-allowed">
          <img
            ngSrc="assets/icons/header_2.svg"
            alt=""
            width="40"
            height="40" />
        </button>
        <button
          [routerLink]="['/auth/mandatory-reviews']"
          routerLinkActive="router-link-active"
          [disabled]="isAdmin || !canAcessMandatoryReview()"
          matTooltip="{{ 'MANDATORY_REVIEW' | translate }}"
          class="disabled:opacity-50 disabled:cursor-not-allowed">
          <img
            ngSrc="assets/icons/mandatory_review.svg"
            alt=""
            width="40"
            height="40" />
        </button>
        <button
          [routerLink]="['/auth/periodic-reviews']"
          routerLinkActive="router-link-active"
          matTooltip="{{ 'REVISOES_PERIODICAS' | translate }}"
          [disabled]="isAdmin || true"
          class="disabled:opacity-50 disabled:cursor-not-allowed">
          <img
            ngSrc="assets/icons/revisoes-periodicas.svg"
            alt=""
            width="40"
            height="40" />
        </button>
        @if (canAcessAnalysisCommissioning()) {
          <button
            (click)="openIframe('commissioning/manage_analysis_commissioning')"
            matTooltip="{{ 'ANALISE_ENTREGA' | translate }}">
            <img
              ngSrc="assets/icons/header_3.svg"
              alt=""
              width="40"
              height="40" />
          </button>
        }
        <button
          [routerLink]="['/auth/admeasurement']"
          routerLinkActive="router-link-active"
          [disabled]="canAcessAdmeasurement()"
          matTooltip="{{ 'AFERICAO' | translate }}"
          class="disabled:opacity-50 disabled:cursor-not-allowed">
          <img
            ngSrc="assets/icons/aferiçoes.svg"
            alt=""
            width="40"
            height="40" />
        </button>
      </div>
      <div class="flex space-x-3 items-center">
        <img ngSrc="assets/icons/logo.svg" alt="" width="300" height="300" />
        <div class="relative">
          <button
            (click)="toggleLanguageDropdown($event)"
            (keydown.enter)="toggleLanguageDropdown($event)"
            (keydown.space)="toggleLanguageDropdown($event)"
            class="flex items-center">
            <img
              ngSrc="assets/icons/header_6.svg"
              alt="Config Icon"
              width="39"
              height="39" />
          </button>
          <div
            *ngIf="isLanguageDropdownVisible"
            class="absolute right-0 mt-2 w-44 bg-[#678694] text-white rounded-lg shadow-lg">
            <div
              *ngFor="let language of languages"
              (click)="changeLanguage(language.value, $event)"
              (keydown.enter)="changeLanguage(language.value)"
              (keydown.space)="changeLanguage(language.value)"
              tabindex="0"
              class="flex items-center justify-start p-2 cursor-pointer">
              <img [src]="language.flag" alt="Flag" class="w-6 h-6" />
              <div class="flex ml-2 items-center group justify-center">
                <span class="hover:text-gray-600 group-hover:text-gray-600">{{
                  language.label
                }}</span>
                <span
                  *ngIf="language.value === selectedLanguage"
                  class="text-xl ml-2 group-hover:text-gray-600">
                  ✔
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="relative mt-1.5">
          <button
            (click)="toggleAvatarDropdown($event)"
            (keydown.enter)="toggleAvatarDropdown($event)"
            (keydown.space)="toggleAvatarDropdown($event)"
            class="icon-button">
            <span class="avatar avatar-header">
              <app-avatar [name]="name"></app-avatar>
            </span>
          </button>

          <div
            *ngIf="isAvatarDropdownVisible"
            class="absolute right-0 mt-[2px] min-w-40 bg-[#0088ce] text-white rounded-lg shadow-lg">
            @if (canViewAdmSystem()) {
              <div
                tabindex="0"
                (click)="gotToAdmSystem($event)"
                (keydown.enter)="gotToAdmSystem()"
                (keydown.space)="gotToAdmSystem()"
                class="flex items-center justify-center p-[6px] cursor-pointer hover:text-gray-600 group-hover:text-gray-600">
                {{ 'ADM' | translate }}
              </div>
            }
            @if (canViewAdmSystem()) {
              <div
                tabindex="0"
                (click)="goToAdmeasurement($event)"
                (keydown.enter)="goToAdmeasurement()"
                (keydown.space)="goToAdmeasurement()"
                class="flex items-center justify-center p-[6px] cursor-pointer hover:text-gray-600 group-hover:text-gray-600">
                {{ 'ADMAFERICAO' | translate }}
              </div>
            }
            <div
              (click)="goToProfile($event)"
              (keydown.enter)="goToProfile()"
              (keydown.space)="goToProfile()"
              tabindex="0"
              class="flex items-center justify-center p-[6px] cursor-pointer hover:text-gray-600 group-hover:text-gray-600">
              {{ 'PERFIL' | translate }}
            </div>
            <div
              (click)="logout()"
              (keydown.enter)="logout()"
              (keydown.space)="logout()"
              tabindex="0"
              class="flex items-center justify-center p-[6px] cursor-pointer hover:text-gray-600 group-hover:text-gray-600 border-t-[1px] border-[#067CB9]">
              {{ 'SAIR' | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="flex-1 p-4 bg-[#f2f7f7]" [ngClass]="{ 'no-padding': isAdmin }">
    <router-outlet></router-outlet>
  </div>

  <div class="sticky bottom-0 z-10">
    <app-footer></app-footer>
  </div>
</div>
